import { ComponentType, memo } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import useApplicationInsights from "src/analytics";
import { defaultQueryClientOptions } from "src/api/client.config";
import DefaultLayout from "src/components/layout/default-layout";
import { ThirdPartyScripts } from "src/components/layout/third-party-scripts";
import { WfHeadMemo } from "src/components/layout/wf-head";
import { Error404 } from "src/components/pages/error/404";
import { Error500 } from "src/components/pages/error/500";
import config from "src/config/index";
import { useInitApp } from "src/hooks/use-init-app";
import customTheme from "src/theme";
import { getErrorPageHeadData } from "src/utils/error-page-utils";
import { initAppHelperUtils } from "src/utils/init-app-helper-utils";

initAppHelperUtils();

const queryClient = new QueryClient({
    defaultOptions: defaultQueryClientOptions,
});

function withProviders<Props>(WrappedComponent: ComponentType<Props>) {
    return function WithProviders(props: Props & JSX.IntrinsicAttributes) {
        return (
            <QueryClientProvider client={queryClient}>
                <ChakraProvider theme={customTheme}>
                    <WrappedComponent {...props} />
                </ChakraProvider>
            </QueryClientProvider>
        );
    };
}

interface IComponentByStatusCode {
    Component: AppProps["Component"];
    pageProps: AppProps["pageProps"];
}
const ComponentByErrorPageCode = ({ Component, pageProps }: IComponentByStatusCode) => {
    switch (pageProps.errorPageCode) {
        case 404:
            return <Error404 />;
        case 500:
            return <Error500 errorCode={pageProps.errorCode} showLogo />;
        default:
            return <Component {...pageProps} />;
    }
};

const ComponentByErrorPageCodeMemo = memo(ComponentByErrorPageCode);

const InitApp = ({ Component, pageProps, router }: AppProps) => {
    useInitApp(pageProps);

    useApplicationInsights({
        appInsightsConfig: { isEnabled: config.isApplicationInsightsEnabled, instrumentationKey: config.applicationInsightsKey },
        appProps: { Component, router },
    });

    return null;
};

function App({ Component, pageProps, router }: AppProps) {
    const headProps = pageProps.errorPageCode === 500 ? getErrorPageHeadData(pageProps.errorPageCode) : pageProps.head;

    return (
        <>
            <InitApp Component={Component} pageProps={pageProps} router={router} />
            <WfHeadMemo {...headProps} />
            <ThirdPartyScripts />
            <DefaultLayout {...pageProps}>
                <ComponentByErrorPageCodeMemo Component={Component} pageProps={pageProps} />
            </DefaultLayout>
        </>
    );
}

export default withProviders(appWithTranslation(App));
