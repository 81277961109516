import React, { useRef } from "react";
import { Skeleton, Text } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
import { Section } from "src/components/base";
import { WfHeading } from "src/components/base/wf-heading";
import { EnumJumpMarkSection } from "src/components/pages/detail/jump-marks/consts";
import { useJumpMarkAnchorRefs } from "src/components/pages/detail/jump-marks/jump-mark-anchor-refs-provider";
import useViewportSpy from "src/hooks/dom/use-viewport-spy";
import { EnumWikifolioStatus } from "src/types/common-enum";
import { ITreemapChartProps } from "./treemap-chart";
import { TreemapChartSkeleton } from "./treemap-chart-skeleton";
import { ITreemapChart } from "./types";

export const TREEMAP_CHART_TEST_ID = "tree-map-chart";

const TreemapChart = dynamic<ITreemapChartProps>(() => import("./treemap-chart").then(component => component.TreemapChart), {
    loading: () => (
        <>
            <Skeleton rounded="8px" mb={2} h="48px" width={["100%", "294px"]} marginLeft="auto" />
            <TreemapChartSkeleton />
        </>
    ),
    ssr: false,
});

export const TreemapChartSection = ({ dict, symbol, creationDate, wikifolioStatus }: ITreemapChart) => {
    const { t } = useTranslation("wf-detail");
    const { scrollName } = useJumpMarkAnchorRefs();

    const isReadyToLoad = scrollName === null || scrollName === EnumJumpMarkSection.KeyFigures;
    const sectionRef = useRef<HTMLDivElement>(null);
    const isVisible = useViewportSpy(isReadyToLoad ? sectionRef : { current: null }, { threshold: 0.5, visibleOnce: true });

    const shouldRenderPnlChart = [
        EnumWikifolioStatus.Investable,
        EnumWikifolioStatus.Published,
        EnumWikifolioStatus.IssuingRequested,
        EnumWikifolioStatus.EmissionProcessStarted,
        EnumWikifolioStatus.ReadyForIssuing,
    ].includes(wikifolioStatus);

    return shouldRenderPnlChart ? (
        <React.StrictMode>
            <Section ref={sectionRef} data-test-id={TREEMAP_CHART_TEST_ID}>
                <WfHeading as="h2" fontSize="2xl" mb={2}>
                    {dict.title}
                </WfHeading>
                <Text mb={4}> {dict.description}</Text>

                {isVisible ? (
                    <TreemapChart symbol={symbol} creationDate={creationDate} chartAriaLabel={t("labels.treemap-chart-aria-label")} />
                ) : (
                    <>
                        <Skeleton rounded="8px" mb={2} h="48px" width={["100%", "294px"]} marginLeft="auto" />
                        <TreemapChartSkeleton />
                    </>
                )}
            </Section>
        </React.StrictMode>
    ) : null;
};
