import { useRef } from "react";
import { useFeedWikifolioNewsQuery } from "src/api/client/feed-wikifolio-news.api";
import { Section } from "src/components/base";
import { WfHeading } from "src/components/base/wf-heading";
import { EmptyState } from "src/components/common/empty-state";
import { useJumpMarkAnchorRefs } from "src/components/pages/detail/jump-marks/jump-mark-anchor-refs-provider";
import { NewsList } from "src/components/pages/detail/news/news-list";
import { NewsListSkeleton } from "src/components/pages/detail/news/news-list-skeleton";
import useViewportSpy from "src/hooks/dom/use-viewport-spy";
import { ITitleAndDescription } from "src/types/common";
import { EnumJumpMarkSection } from "../jump-marks/consts";

const NEWS_LIST_SECTION_TEST_ID = "news-feed-section";

export interface INewsListSectionProps {
    wikifolioId: string;
    headline: string;
    emptyState: ITitleAndDescription;
}

export const NewsListSection = ({ wikifolioId, headline, emptyState }: INewsListSectionProps) => {
    const ref = useRef(null);
    const { scrollName } = useJumpMarkAnchorRefs();
    const isReadyToLoad = scrollName === null || scrollName === EnumJumpMarkSection.Portfolio;

    const isSectionVisible = useViewportSpy(isReadyToLoad ? ref : { current: null }, { threshold: 0.5, visibleOnce: true });

    const { data, isLoading } = useFeedWikifolioNewsQuery(wikifolioId, { enabled: isSectionVisible });

    return (
        <Section ref={ref} data-test-id={NEWS_LIST_SECTION_TEST_ID}>
            <WfHeading as="h2" fontSize="2xl" mb={2}>
                {headline}
            </WfHeading>
            {(isLoading || data === undefined) && <NewsListSkeleton />}
            {!isLoading && data?.items?.length ? <NewsList items={data.items} /> : null}
            {!isLoading && data !== undefined && !data.items?.length ? (
                <EmptyState title={emptyState.title} description={emptyState.description} />
            ) : null}
        </Section>
    );
};
