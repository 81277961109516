import { SystemStyleObject, useStyleConfig } from "@chakra-ui/react";
import { ISimpleRichTextFontSize } from "src/components/common/rich-text/simple-rich-text";
import { svgAvatar, svgCheckMark, svgUnderlying, svgWikifolio } from "./constants";
import { getIconRule } from "./utils";

interface IStyles {
    linkGray: SystemStyleObject;
    fontSize?: ISimpleRichTextFontSize;
}
const getSx = ({ linkGray }: IStyles): SystemStyleObject => ({
    p: {
        mb: 3,
        _last: {
            mb: 0,
        },
    },
    ul: {
        ml: [3, 5],
    },
    "ul.checkmark": {
        listStyle: `url("data:image/svg+xml;utf8,${svgCheckMark}")`,
    },
    ol: {
        ml: [3, 5],
        counterReset: "customlistcounter",
        li: {
            counterIncrement: "customlistcounter",
            "::marker": {
                content: 'counter(customlistcounter) "  "',
                fontWeight: "bold",
            },
        },
    },
    li: {
        my: 2,
    },
    'a[class^="icon"]': {
        display: "inline-flex",
        alignItems: "baseline",
        lineHeight: "1",
    },
    "a:not(.button, .arrow-link)": linkGray,
    ".icon-profile": getIconRule(svgAvatar),
    ".icon-underlying": getIconRule(svgUnderlying),
    ".icon-wf": getIconRule(svgWikifolio),
});
export interface ISimpleRichTextProps {
    text: string;
}
export const useSimpleRichText = (fontSize?: ISimpleRichTextFontSize) => {
    const linkGray = useStyleConfig("Link", { variant: "underline", colorScheme: "gray" });

    return getSx({ linkGray, fontSize });
};
