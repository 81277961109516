import { SystemStyleObject } from "@chakra-ui/react";

const getContent = (svg: string) => `url("data:image/svg+xml;utf8,${svg}")`;

export const getIconRule = (svgString: string): SystemStyleObject => {
    return {
        whiteSpace: "nowrap",
        span: {
            whiteSpace: "normal",
        },
        _before: {
            content: getContent(svgString),
            display: "inline-flex",
            alignSelf: "center",
            boxSize: "16px",
            mr: 0.5,
            verticalAlign: "middle",
        },
    };
};
