import { Icon, IconButton, IconButtonProps } from "@chakra-ui/react";
import { motion, MotionProps } from "motion/react";

export const Path = (props: MotionProps & Record<string, unknown>) => (
    <motion.path fill="transparent" strokeWidth="2" stroke="currentColor" strokeLinecap="round" {...props} />
);

export const Hamburger = (props: IconButtonProps) => {
    return (
        <IconButton
            variant="ghost"
            colorScheme="gray"
            icon={
                <Icon pos="relative" boxSize="24px" viewBox="0 0 24 24">
                    <Path
                        variants={{
                            closed: { d: "M 5 6 L 19 6" },
                            open: { d: "M 5 19 L 19 5" },
                        }}
                    />
                    <Path
                        d={"M 11 12 L 19 12"}
                        variants={{
                            closed: { opacity: 1 },
                            open: { opacity: 0 },
                        }}
                        transition={{ duration: 0.1 }}
                    />
                    <Path
                        variants={{
                            closed: { d: "M 5 18 L 19 18" },
                            open: { d: "M 5 5 L 19 19" },
                        }}
                    />
                </Icon>
            }
            {...props}
        />
    );
};
